<template>

  <div class="row delivery">
    <div class="col">
      <h4>
        {{ delivery.name }}
      </h4>
      <button class="btn btn-primary update" type="button" @click="updateDelivery()">{{$t('shop.settings.methodsOfDelivery.update-button')}}</button>
      <button class="btn btn-primary update" type="button" @click="viewAssociatedProducts()">{{$t('shop.settings.methodsOfDelivery.associated-products-button')}}</button>
      <button class="btn btn-primary update" type="button" @click="confirmRemoveDelivery()">{{$t('shop.settings.methodsOfDelivery.delete-button')}}</button>
    </div>

    <b-modal ref="removeDeliveryModal" 
        hide-header
        @ok="removeDelivery">
      {{ $t('shop.settings.methodsOfDelivery.delete-confirmation') }}
    </b-modal>
    
    <b-modal size="xl" ref="updateModal" :title="$t('shop.settings.methodsOfDelivery.update.title')" hide-footer lazy>
      <PickupForm v-if="delivery.type == 'PICKUP'" :pickup="delivery" @cancel="onCancelUpdate"></PickupForm>
      <ShippingForm v-else-if="delivery.type == 'SHIPPING'" :shipping="delivery" @cancel="onCancelUpdate"></ShippingForm>
    </b-modal>

    <b-modal size="xl" ref="associatedProductsModal" :title="$t('shop.settings.methodsOfDelivery.products.title')" hide-footer lazy>
      <AssociatedProducts :methodOfDelivery="delivery" />
    </b-modal>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useShopAdmin } from '../../../../composables/useShopAdmin';
import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import AssociatedProducts from './AssociatedProducts.vue';
import PickupForm from './PickupForm.vue'
import ShippingForm from './ShippingForm.vue'


export default defineComponent({
  props: {
      delivery : {
        type: Object,
        required: true
      }
  },
  components: {
    TextField,
    AssociatedProducts,
    PickupForm,
    ShippingForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectedShop } = useShopAdmin(props, context);
    const { removeMethodOfDelivery } = useMethodsOfDeliveryAdmin(props, context);

    const { delivery } = toRefs(props);

    const removeDeliveryModal:Ref<HTMLElement|null> = ref(null);
    const updateModal:Ref<HTMLElement|null> = ref(null);
    const associatedProductsModal:Ref<HTMLElement|null> = ref(null);

    const confirmRemoveDelivery = () => {
      // @ts-ignore
      removeDeliveryModal.value.show()
    }

    const removeDelivery = () => {
      removeMethodOfDelivery(delivery.value).then((removed:boolean) => {
        if(removed) {  
          // We emit event as updated
          context.emit('delivery-removed');
        }
      })
    }

    const updateDelivery = () => {
      // @ts-ignore
      updateModal.value.show()
    }

    const onCancelUpdate = () => {
      // @ts-ignore
      updateModal.value.hide()
    }

    const viewAssociatedProducts = () => {
      // @ts-ignore
      associatedProductsModal.value.show()
    }

    return {
      removeDeliveryModal,
      confirmRemoveDelivery,
      removeDelivery,
      updateModal,
      updateDelivery,
      onCancelUpdate,
      associatedProductsModal,
      viewAssociatedProducts
    }
  }
})
</script>