<template>

    <validation-observer ref="pricesObserver" slim>
      <form role="form" @submit="onFormSubmit" name="price-form" class="price-form">

        <b-table 
            outlined striped
            :items="listLocations"
            :fields="listLocationsFields"
            ref="listLocations">
          <template v-slot:cell(countryCode)="row">
            <validation-provider :skipIfEmpty="false" v-slot="{ errors, classes }" slim>
              <span :class="{...classes}">
                <v-select :ref="'listCountries'+row.index" required :options="listCountries" :reduce="country => country.code.toLowerCase()" label="label" :id="'updateCountry_'+row.index" :class="{'form-control':true, 'required':true, ...classes}" :placeholder="$t('shop.settings.methodsOfDelivery.shipping.price.update.country_placeholder')" @keypress.enter.native.prevent="" v-model="row.item.countryCode" />
                <div :class="{'invalid-feedback':true}" v-if="errors.length > 0">{{ errors[0] }}</div>
              </span>
            </validation-provider>
          </template>
          <template v-slot:cell(postalCodeRegExps)="row">
            <validation-provider :skipIfEmpty="false" v-slot="{ errors, classes }" slim v-if="listRegions(row.index).length > 0">
              <span :class="{...classes}">
                <v-select ref="listRegions" multiple :options="listRegions(row.index)" :getOptionLabel="(code) => $t('shop.settings.methodsOfDelivery.shipping.locations.' + code)" :id="'updateRegions_'+row.index" :class="{'form-control':true, ...classes}" :placeholder="$t('shop.settings.methodsOfDelivery.shipping.price.update.regions_placeholder')" @keypress.enter.native.prevent="" v-model="row.item.postalCodeRegExps"/>
                <div :class="{'invalid-feedback':true}" v-if="errors.length > 0">{{ errors[0] }}</div>
              </span>
            </validation-provider>
            <div v-else-if="row.item.countryCode != null" class="col-form-label">
              {{ $t('shop.settings.methodsOfDelivery.shipping.price.update.no-region') }}
            </div>
            <div v-else class="col-form-label">
              {{ $t('shop.settings.methodsOfDelivery.shipping.price.update.no-country-selected') }}
            </div>
          </template> 
          <template v-slot:cell(options)="row">
            <div class="col-form-label">
              <a href="javascript:void(0)" v-if="form.locations.length > 1" @click="removeLocationItem(row.index)">{{$t('shop.settings.methodsOfDelivery.shipping.price.update.remove-location-button')}}</a>
            </div>
          </template>
        </b-table>

        <p><button class="btn btn-primary" type="button" :disabled="!isAddLocationAllowed" @click="addLocation()">{{$t('shop.settings.methodsOfDelivery.shipping.price.update.add-location-button')}}</button></p>

        <b-table 
            outlined striped
            :items="listPricePerItems"
            :fields="listPricePerItemsFields"
            ref="listPricePerItems">
          <template v-slot:cell(facts)="row">

            <div class="col">
              
              <validation-provider 
                  v-for="(fact) in facts" :key="row.index + '-' + fact.key + '-price'"
                  :rules="getFactValidationRule(fact.key, row.index)" 
                  :skipIfEmpty="false" 
                  v-slot="{ errors, classes }" 
                  slim 
                >
                <div v-if="row.item.facts[fact.key] !== undefined" :class="{'form-group':true, 'row':true, ...classes}">
                  <label class="col-form-label">{{$t('shop.settings.methodsOfDelivery.shipping.price.update.'+fact.key)}}</label>
                  <div :class="{'col-auto':true,...classes}">
                    <div class="input-group">
                      <input v-if="fact.type == 'number'" type="number" v-model.number="row.item.facts[fact.key]" :class="{'form-control':true, ...classes}" />
                      <input v-else-if="fact.type == 'price'" type="number" v-model.number="row.item.facts[fact.key].amount" :class="{'form-control':true, ...classes}" />
                      <span class="input-group-append">
                        <button type="button" :class="{'btn':true, 'btn-white':true, 'form-control':true, ...classes}" @click="removeFact(fact.key, row.index)">x</button>
                      </span>
                      <div :class="{'invalid-feedback':true}" v-if="errors.length > 0">{{ errors[0] }}</div>
                    </div>
                  </div>
                </div>
              </validation-provider>

              <div class="row">
                <button type="button" class="btn btn-white" @click="addFact(row.index)">{{ $t('shop.settings.methodsOfDelivery.shipping.price.update.add-fact-button') }}</button>
              </div>

            </div>

          </template>
          <template v-slot:cell(output)="row">
            <validation-provider :rules="priceValidationRule(row.index)" :skipIfEmpty="false" v-slot="{ errors, classes }" slim>
              <span :class="{...classes}">
                <input type="number" v-model.number="row.item.output.price.amount" :class="{'form-control':true, ...classes}" /> Euros
                <div :class="{'invalid-feedback':true}" v-if="errors.length > 0">{{ errors[0] }}</div>
              </span>
            </validation-provider>
          </template> 
          <template v-slot:cell(options)="row">
            <div class="col-form-label">
              <a href="javascript:void(0)" v-if="form.pricePerItems.length > 1" @click="removePricePerItem(row.index)">{{$t('shop.settings.methodsOfDelivery.shipping.price.update.remove-range-button')}}</a>
            </div>
          </template>
        </b-table>

        <p v-if="this.form.pricePerItems.length > 0 && this.form.pricePerItems[this.form.pricePerItems.length - 1].maxNumberOfItems && this.form.pricePerItems[this.form.pricePerItems.length - 1].price.amount > 0">
          {{ $t('shop.settings.methodsOfDelivery.shipping.price.update.freeDeliveryFrom',[(this.form.pricePerItems[this.form.pricePerItems.length - 1].maxNumberOfItems) + 1]) }}
        </p>
        
        <p><button class="btn btn-primary" type="button" :disabled="!isAddRangeAllowed" @click="addRange()">{{$t('shop.settings.methodsOfDelivery.shipping.price.update.add-range-button')}}</button></p>

        <button class="btn btn-primary" type="submit" :disabled="!isFormValid()">{{ buttonLabel }}</button>
      
      </form>

      <b-modal size="lg" ref="addFactModal" 
          :title="$t('shop.settings.methodsOfDelivery.shipping.price.addFact.title')"
          hide-footer>
        <form role="form" @submit="onAddFactFormSubmit" name="fact-form" class="fact-form">
          <h3>{{ $t('shop.settings.methodsOfDelivery.shipping.price.addFact.select') }}</h3>
          <v-select :ref="'listFacts'" required :options="factForm.listFacts" :reduce="fact => fact.key" :getOptionLabel="(fact) => $t('shop.settings.methodsOfDelivery.shipping.price.update.' + fact.key)" :id="'addFact'" :class="{'form-control':true, 'required':true}" :placeholder="$t('shop.settings.methodsOfDelivery.shipping.price.addFact.fact_placeholder')" @keypress.enter.native.prevent="" v-model="factForm.factKey" />
          <p>
            <button class="btn btn-primary" type="submit">{{ $t('shop.settings.methodsOfDelivery.shipping.price.addFact.button') }}</button>
          </p>
        </form>
      </b-modal>
    </validation-observer>

</template>

<style>

.price-form table input.form-control {
  display:inline;
  width:80px;
}
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import { extend } from "vee-validate";
import * as api from '@fwk-client/utils/api';

@Component({
  components: {
    TextField
   }
})
export default class PricesForm extends Vue {

  /**
   * shippingPrice
   *  locations:ShippingLocation[],
   *  pricePerItems: PricePerItem[]
   */
  @Prop({
    type: Object,
    required: false
  }) readonly price!: any | undefined

  @Prop({
    type: Number,
    required: false
  }) readonly minimumNumberOfItems?: number

  /**
   * pricePerItem
   *  facts: {
   *     maxNumberOfItems?: number, 
   *     minNumberOfItems?:number, 
   *     minProductsAmount?: PriceInput, 
   *     maxProductsAmount?: PriceInput 
   *  },
   *  output: {
   *     price:PriceInput
   *  }
   */
  emptyPricePerItem = {
    facts : {},
    output: {
      price: {
        nonDiscountedAmount: undefined,
        amount: 0,
        currencyCode: "EUR"
      }
    }
  }

  emptyForm = {
    locations : [{
      countryCode: null,
      postalCodeRegExps: []
    }],
    pricePerItems : [
      JSON.parse(JSON.stringify(this.emptyPricePerItem))
    ]
  }

  form = this.price ? JSON.parse(JSON.stringify(this.price)) : {...this.emptyForm};
  factForm:any = {
    index: null,
    factKey: null,
    listFacts: []
  }

  facts = [
    {
      key: 'minNumberOfItems',
      type: 'number'
    },
    {
      key: 'maxNumberOfItems',
      type: 'number'
    },
    {
      key: 'minProductsAmount',
      type: 'price'
    },
    {
      key: 'maxProductsAmount',
      type: 'price'
    }
  ]

  listLocationsFields:any = [
      {
        key: "countryCode",
        label: ""
      },
      {
        key: "postalCodeRegExps",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
  ]

  get listLocations() {
    var locations = [
      ...this.form.locations
    ]

    return locations; 
  }

  get countryCodes() {
    return this.listLocations.map((location) => {
      return location.countryCode;
    })
  }

  get buttonLabel() {
    if(this.price) { return this.$t('shop.settings.methodsOfDelivery.shipping.price.update.button') }
    else { return this.$t('shop.settings.methodsOfDelivery.shipping.price.create.button'); }
  }

  get isAddLocationAllowed() {
    // We disable if there is at least one location where country code is not yet selected.
    return this.form.locations.map((location:any) => {
      if(!location.countryCode) { return "empty"; }
      else { return "filled"; }
    }).indexOf("empty") == -1
  }

  get isAddRangeAllowed() {
    var isAllowed = true;
    // We cannot add range if the last item has no maxNumberOfItems and no maxProductsAmount defined
    if(this.form.pricePerItems.length > 0 && 
        this.form.pricePerItems[this.form.pricePerItems.length - 1].facts &&
        this.form.pricePerItems[this.form.pricePerItems.length - 1].facts.maxNumberOfItems === undefined && 
        this.form.pricePerItems[this.form.pricePerItems.length - 1].facts.maxProductsAmount === undefined) {
      isAllowed = false;
    }
    return isAllowed;
  }

  listPricePerItemsFields:any = [
      {
        key: "facts",
        label: ""
      },
      {
        key: "output",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
  ]

  get listPricePerItems() {
    var pricePerItems = [
      ...this.form.pricePerItems
    ]

    return pricePerItems; 
  }
  
  listRegions(indexLocation:number) {
    var countryCode = this.form.locations[indexLocation].countryCode;
    if(!countryCode) {
      return [];
    }
    else if(countryCode.toUpperCase() == "FR") {
      return ["MONACO","CORSE","FR:06","DOM","TOM","FRANCE_METROPOLITAINE"]
    }
    else {
      return []
    }
  }

  priceValidationRule(index:number) {
    var validation:any = {
      shippingPricePrice: {
        index: index
      },
      "required" : true
    }
    return validation;
  }

  addShippingPricePriceValidation() {
    var componentInstance = this;
    extend('shippingPricePrice',{
      params: ['index'],
      validate(amount, params:any):Promise<boolean|string> {

        if(amount == undefined || isNaN(amount)) {
          return Promise.resolve("Obligatoire et un nombre");
        }
        else if(amount < 0) {
          return Promise.resolve("Le montant doit etre positif ou 0");
        }
        
        return Promise.resolve(true);
      }
    });
  }

  getFactValidationRule(factKey:string, index:number) {
    var validationRule = "shippingPrice" + factKey[0].toUpperCase() + factKey.substring(1);
    var validation:any = {
      [validationRule]: {
        index: index
      }
    }
    return validation;

  }

  addMinNumberOfItemsValidation() {
    var componentInstance = this;
    extend('shippingPriceMinNumberOfItems',{
      params: ['index'],
      validate(minNumberOfItems, params:any):Promise<boolean|string> {

        // We first check that the field exists to validate it
        if(componentInstance.form.pricePerItems[params.index].facts.minNumberOfItems == undefined) {
          return Promise.resolve(true);
        }

        // We check that we have a positive number
        var numberOfItems = componentInstance.form.pricePerItems[params.index].facts.minNumberOfItems;
        if(isNaN(numberOfItems)) {
          return Promise.resolve("Entrez un nombre");
        }
        else if((componentInstance.minimumNumberOfItems != undefined && numberOfItems < componentInstance.minimumNumberOfItems) || numberOfItems < 0) {
          return Promise.resolve("Trop petit");
        }
        
        return Promise.resolve(true);
      }
    });
  }

  addMaxNumberOfItemsValidation() {
    var componentInstance = this;
    extend('shippingPriceMaxNumberOfItems',{
      params: ['index'],
      validate(maxNumberOfItems, params:any):Promise<boolean|string> {

        // We first check that the field exists to validate it
        if(componentInstance.form.pricePerItems[params.index].facts.maxNumberOfItems == undefined) {
          return Promise.resolve(true);
        }

        if(params.index < (componentInstance.form.pricePerItems.length - 1) &&
            (!maxNumberOfItems || isNaN(maxNumberOfItems) || maxNumberOfItems == 0)) {
          return Promise.resolve("Obligatoire et un nombre");
        }
        else if(params.index == 0 && componentInstance.minimumNumberOfItems && maxNumberOfItems < componentInstance.minimumNumberOfItems) {
          return Promise.resolve("Trop petit")
        }
        else if(params.index > 0 && maxNumberOfItems && maxNumberOfItems < (componentInstance.form.pricePerItems[params.index - 1].facts.maxNumberOfItems + 1)) {
          return Promise.resolve("Trop petit")
        }
        
        return Promise.resolve(true);
      }
    });
  }

  addMinProductsAmountValidation() {
    var componentInstance = this;
    extend('shippingPriceMinProductsAmount',{
      params: ['index'],
      validate(minProductsAmount, params:any):Promise<boolean|string> {

        // We first check that the field exists to validate it
        if(componentInstance.form.pricePerItems[params.index].facts.minProductsAmount == undefined) {
          return Promise.resolve(true);
        }

        // We check that we have amount positive or 0
        var amount = componentInstance.form.pricePerItems[params.index].facts.minProductsAmount.amount;
        if(isNaN(amount)) {
          return Promise.resolve("Entrez un nombre");
        }
        else if(amount < 0 ) {
          return Promise.resolve("Le montant doit etre un nombre positif ou gratuit");
        }
        
        return Promise.resolve(true);
      }
    });
  }

  addMaxProductsAmountValidation() {
    var componentInstance = this;
    extend('shippingPriceMaxProductsAmount',{
      params: ['index'],
      validate(maxProductsAmount, params:any):Promise<boolean|string> {

        // We first check that the field exists to validate it
        if(componentInstance.form.pricePerItems[params.index].facts.maxProductsAmount == undefined) {
          return Promise.resolve(true);
        }

        // We check that we have amount positive or 0
        var amount = componentInstance.form.pricePerItems[params.index].facts.maxProductsAmount.amount;
        if(isNaN(amount)) {
          return Promise.resolve("Entrez un nombre");
        }
        else if(amount < 0 ) {
          return Promise.resolve("Le montant doit etre un nombre positif ou gratuit");
        }
        
        return Promise.resolve(true);
      }
    });
  }

  created() {
    this.updateListCountries();
    this.addMinNumberOfItemsValidation();
    this.addMaxNumberOfItemsValidation();
    this.addMinProductsAmountValidation();
    this.addMaxProductsAmountValidation();
    this.addShippingPricePriceValidation();
  }

  mounted() {
    this.listLocationsFields[0].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.country') as string;
    this.listLocationsFields[1].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.regions') as string;
    this.listLocationsFields[2].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.options') as string;

    this.listPricePerItemsFields[0].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.facts') as string;
    this.listPricePerItemsFields[1].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.output') as string;
    this.listPricePerItemsFields[2].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.update.options') as string;

    // @ts-ignore
    this.$refs.pricesObserver.validate();
  }

  listCountries:any[] = [];
  updateListCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listCountries', options).then((response:any) => {
      if(response.countries) {  
        this.listCountries = response.countries;
        var _self = this;
        for(var indexLocation in _self.form.locations) {
          var location = _self.form.locations[indexLocation];
          if(location.countryCode && location.countryCode != "") {
            // We get the value from countryCode
            var selected = this.listCountries.find(function(element) {
              return element.code == location.countryCode.toUpperCase();
            });
            if(selected) {
              // @ts-ignore
              _self.$refs['listCountries'+indexLocation].updateValue(selected);
            }
          }
        }
      }
    });
  }

  addLocation() {
    this.form.locations.push({
      countryCode: null,
      postalCodeRegExps: []
    });
  }

  addRange() {
    var rule = JSON.parse(JSON.stringify(this.emptyPricePerItem));
    
    // We add new price per item
    this.form.pricePerItems.push(rule);

    Vue.nextTick(() => {
      // @ts-ignore
      this.$refs.pricesObserver.validate();
    })
  }

  removeLocationItem(index:number) {
    this.form.locations.splice(index,1);
  }

  removePricePerItem(index:number) {
    this.form.pricePerItems.splice(index,1);
  }

  addFact(index:number) {
    // We update the index
    this.factForm.index = index;
    // We update the list of available facts.
    var existingFactKeys = Object.keys(this.form.pricePerItems[this.factForm.index].facts);
    this.factForm.listFacts = this.facts.filter((fact) => { return existingFactKeys.indexOf(fact.key) == -1 });
    // @ts-ignore
    this.$refs.addFactModal.show()
  }

  onAddFactFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    // We add the selected fact to the pricePerItem
    var emptyValue:any = undefined;
    var selectedFact = this.facts.filter((fact) => { return fact.key == this.factForm.factKey })[0];
    if(selectedFact.type == "number") {
      emptyValue = null;
      if(selectedFact.key == "maxNumberOfItems" && !isNaN(this.form.pricePerItems[this.factForm.index].facts["minNumberOfItems"])) {
        emptyValue = this.form.pricePerItems[this.factForm.index].facts["minNumberOfItems"];
      }
      else if(selectedFact.key == "minNumberOfItems" || selectedFact.key == "maxNumberOfItems") {
        if(this.minimumNumberOfItems == undefined) { emptyValue = 0; }
        else { emptyValue = this.minimumNumberOfItems; }
      }
    }
    else if(selectedFact.type == "price") {
      emptyValue = {
        amount : null
      };
    }
    Vue.set(this.form.pricePerItems[this.factForm.index].facts, this.factForm.factKey, emptyValue);
    
    // @ts-ignore
    this.$refs.addFactModal.hide()

    // We reset the form
    this.factForm = {
      index: null,
      factKey: null,
      listFacts: []
    }
  }

  removeFact(factKey:string, index:number) {
    Vue.set(this.form.pricePerItems[index].facts, factKey, undefined);
    delete this.form.pricePerItems[index].facts[factKey];
  }

  isFormValid() {
    return this.$refs.pricesObserver && (this.$refs.pricesObserver as any).flags.valid
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    // We update the price
    this.emitValueChange();
  }

  emitValueChange() {
    var price = JSON.parse(JSON.stringify(this.form));
    
    // We remove empty locations
    price.locations = price.locations.filter((location:any) => location.countryCode != null);

    if(this.price) {  
      this.$emit('price-updated', price);
    }
    else {  
      this.$emit('price-created', price);
    }
  }

  @Watch('countryCodes')
  onCountryCodesChange(val:any, oldVal:any) {
    if(val.length > oldVal.length) {
      // We added a new country nothing to do
    }
    else if(val.length < oldVal.length) {
      // We removed a country nothing to do
    }
    else {
      // We updated one contry code.
      for(var index = 0; index < val.length; index++) {
        if(oldVal[index] != null && 
            (val[index] == null || oldVal[index].toUpperCase() != val[index].toUpperCase())) {
          // We changed this index to empty or different country so we need to reset the list of regions selected
          this.form.locations[index].postalCodeRegExps = [];
        }
      }
    }

  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListCountries();
  }
}
</script>