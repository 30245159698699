<template>
  <div class="shippingPrice form-group row">
    <label for="updateShippingPrice" class="col-lg-3 col-form-label">
      {{$t('shop.settings.methodsOfDelivery.shipping.shippingPrice')}}
    </label>
    <div class="col-lg-9">
      <b-table 
          outlined striped
          :items="listPrices"
          :fields="listFields"
          show-empty
          :empty-text="$t('shop.settings.methodsOfDelivery.shipping.price.list.empty')"
          ref="listPrices">
        <template v-slot:cell(locations)="row">
          <div v-for="(locationPrice, indexLocation) in row.item.locations" :key="indexLocation + '-location'">
            {{ $t("COUNTRIES."+locationPrice.countryCode.toUpperCase()) }}
            {{ locationPrice.postalCodeRegExps ? " (" + locationPrice.postalCodeRegExps.map((locationCode) => { return $t("shop.settings.methodsOfDelivery.shipping.locations."+locationCode); }).join(', ') + ")" : ""}}
          </div>
        </template> 
        <template v-slot:cell(pricePerItems)="row">
          <PricePerItems v-if="row.item.pricePerItems" :pricePerItems="row.item.pricePerItems" :minimumNumberOfItems="form.minimumNumberOfItems" :itemType="shipping.itemType" />
        </template> 
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="showUpdateModal(row.index)">{{$t('shop.settings.methodsOfDelivery.shipping.price.list.options.update')}}</a>  /
          <a href="javascript:void(0)" @click="confirmRemoveItem(row.index)">{{$t('shop.settings.methodsOfDelivery.shipping.price.list.options.delete')}}</a>
        </template>
      </b-table>

      <textarea v-if="hasUserRole(apiRoles.superadmin)" id="updateShippingPrice" :placeholder="$t('shop.settings.methodsOfDelivery.shipping.shippingPrice_placeholder')" class="form-control" v-model="shippingPricesText" />

      <button class="btn btn-primary" type="button" @click="showCreateItemModal()">{{$t('shop.settings.methodsOfDelivery.shipping.price.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :title="$t('shop.settings.methodsOfDelivery.shipping.price.create.title')" :no-enforce-focus="true" no-close-on-backdrop hide-footer lazy>
        <PricesForm v-on:price-created="onItemCreated" :minimumNumberOfItems="form.minimumNumberOfItems"  />
      </b-modal>
      
      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.settings.methodsOfDelivery.shipping.price.update.title')" :no-enforce-focus="true" no-close-on-backdrop hide-footer lazy>
        <PricesForm v-if="itemToUpdate != null" :price="form.shippingPrices[itemToUpdate]" v-on:price-updated="onItemUpdated" :minimumNumberOfItems="form.minimumNumberOfItems" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.promoCodes.scopes.list.delete-confirmation')}}
      </b-modal>
    </div>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import PricesForm from './PricesForm.vue';
import PricePerItems from './PricePerItems.vue';
import { authenticationTypes } from '@fwk-client/store/types';
import { mapGetters } from '@fwk-node-modules/vuex';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';

@Component({
  components: { 
    PricesForm,
    PricePerItems
  },
  computed: {
    ...mapGetters({
          hasUserRole : 'authentication/' + authenticationTypes.getters.HAS_USER_ROLE
    })
  }
})
export default class ShippingPrices extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly shipping!: any

  emptyForm:any = {
    minimumNumberOfItems : null,
    shippingPrices : []
  }

  form:any = { ...this.emptyForm }
  shippingPricesText = "";

  itemToUpdate:any = null;
  itemToRemove:any = null;

  coutryCodeToUpdate = "";

  apiRoles = apiRoles;

  listFields:any = [
      {
        key: "locations",
      },
      {
        key: "pricePerItems",
      },
      {
        key: "options",
      }
  ]

  listPrices() {
    return this.form.shippingPrices;
  }

  created() {
    this.form = this.shipping ? this.shipping : { ...this.emptyForm }
  }

  mounted() {
    this.listFields[0].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.list.headers.locations') as string;
    this.listFields[1].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.list.headers.pricePerItems') as string;
    this.listFields[2].label = this.$t('shop.settings.methodsOfDelivery.shipping.price.list.headers.options') as string;
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  onItemCreated(createdPrice:any) {
    // We update the shipping price
    this.form.shippingPrices.push(createdPrice);
    this.shippingPricesText = JSON.stringify(this.form.shippingPrices, null, 2);
    // @ts-ignore
    this.$refs.createItemModal.hide()
    // @ts-ignore
    this.$refs.listPrices.refresh()
  }

  showUpdateModal(index:any) {
    this.itemToUpdate = index;
    // @ts-ignore
    this.$refs.updateItemModal.show()
  }

  onItemUpdated(updatedPrice:any) {
    // We update the promos
    this.form.shippingPrices[this.itemToUpdate] = updatedPrice;
    this.shippingPricesText = JSON.stringify(this.form.shippingPrices, null, 2);
    this.itemToUpdate = null;
    // @ts-ignore
    this.$refs.updateItemModal.hide()
    // @ts-ignore
    this.$refs.listPrices.refresh()
  }

  confirmRemoveItem(index:any) {
    this.itemToRemove = index;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  removeItem() {
    this.form.shippingPrices.splice(this.itemToRemove, 1);
    this.shippingPricesText = JSON.stringify(this.form.shippingPrices, null, 2);
    this.itemToRemove = null;
  }

  @Watch('shippingPricesText')
  onshippingPricesTextChange(val:any, oldVal:any) {
    this.form.shippingPrices = JSON.parse(val);
  }

  @Watch('shipping', { deep: true })
  onShippingChange(val: any, oldVal: any) {
    if(val) {
      this.form = val;
    }
    else {
      this.form = { ...this.emptyForm }
    }
    this.shippingPricesText = JSON.stringify(this.form.shippingPrices, null, 2)
    // @ts-ignore
    this.$refs.listPrices.refresh()
  }
  

  @Watch('form', { deep: true })
  onFormChange(val: any, oldVal: any) {
    this.emitValueChange();
  }

  emitValueChange() {
    this.$emit('update:shipping', this.form);
  }
}
</script>