<template>

  <div class="products">
    <h2>{{ $t('shop.settings.methodsOfDelivery.products.title') }}</h2>
    
    <div :class="'p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <b-table 
          outlined striped
          :items="listProducts"
          :fields="listFields"
          responsive
          ref="listItems">
        <template v-slot:cell(description)="row">
          <div v-html="getLocalizedText(row.item.description)" ></div>
        </template>
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.settings.methodsOfDelivery.products.options.delete')}}</a>
        </template>
      </b-table>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.settings.methodsOfDelivery.products.delete-confirmation', {productID: itemToRemove._id, productTitle: itemToRemove.title})}}
      </b-modal>

      <form role="form" @submit="onAddProductFormSubmit" name="add-product" v-if="listProductsToAdd.length > 0">
        <div class="form-group row">
            <label for="type" class="col-lg-3 col-form-label">{{$t('shop.settings.methodsOfDelivery.products.product')}}</label>
            <div class="col-lg-9">
                <v-select id="type" :placeholder="$t('shop.settings.methodsOfDelivery.products.product_placeholder')" v-model="itemToAdd" :options="listProductsToAdd" :getOptionLabel="option => option.title"></v-select>
            </div>
        </div>
        <button class="btn btn-primary ladda-button add-product" data-style="zoom-in" type="submit">{{$t('shop.settings.methodsOfDelivery.products.add-button')}}</button>
      </form>
        
    </div>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useShopAdmin } from '../../../../composables/useShopAdmin';
import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';


export default defineComponent({
  props: {
      methodOfDelivery : {
        type: Object,
        required: true
      }
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectedShop } = useShopAdmin(props, context);
    const { isListLoading, getMethodAssociatedProducts, getListEligibleProductsToAdd, associateProductToMethod, removeProductFromMethod } = useMethodsOfDeliveryAdmin(props, context);

    const { methodOfDelivery } = toRefs(props);

    const removeItemModal:Ref<HTMLElement|null> = ref(null);

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const itemToRemove:Ref<any> = ref({});
    const itemToAdd:Ref<any> = ref(null);

    const listProducts:Ref<any[]> = ref([]);
    const listProductsToAdd:Ref<any[]> = ref([]);

    const productTypeFormatter = () => {
      return (value:any, key:any, item:any) => {
        return app.$t('shop.products.products.types.'+value) as string;
      }
    }

    const listFields = [
      {
        key: "type",
        label: "",
        formatter: productTypeFormatter()
      },
      {
        key: "reference",
        label: ""
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "category.title",
        label: ""
      },
      {
        key: "price.amount",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return app.formatPriceAmount(item.price);
        }
      },
      {
        key: "description",
        label: ""
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[0].label = app.$t('shop.products.products.headers.type') as string;
      listFields[1].label = app.$t('shop.products.products.headers.reference') as string;
      listFields[2].label = app.$t('shop.products.products.headers.title') as string;
      listFields[3].label = app.$t('shop.products.products.headers.category') as string;
      listFields[4].label = app.$t('shop.products.products.headers.price') as string;
      listFields[5].label = app.$t('shop.products.products.headers.description') as string;
      listFields[6].label = app.$t('shop.products.products.headers.options') as string;
    })
  
    const updateListProducts = () => {
      getMethodAssociatedProducts(methodOfDelivery.value).then((associatedProducts:any[]) => {
        listProducts.value = associatedProducts
      });
    }
    updateListProducts();

    const updateListProductsToAdd = () => {
      getListEligibleProductsToAdd(methodOfDelivery.value).then((productsToAdd:any[]) => {
        listProductsToAdd.value = productsToAdd;
      });
    }
    updateListProductsToAdd()

    

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const removeItem = () => {

      removeProductFromMethod(itemToRemove.value._id, methodOfDelivery.value).then((removed:boolean) => {
        if(removed) {
          // We update the list of products to add
          updateListProductsToAdd();
          // We update the list of associated products
          updateListProducts();
        }
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const onAddProductFormSubmit = (evt:Event) => {
      evt.preventDefault();

      associateProductToMethod(itemToAdd.value._id, methodOfDelivery.value).then((associated:boolean) => {
        if(associated) {
          // We update the list of products to add
          updateListProductsToAdd();
          // We update the list of associated products
          updateListProducts();
        }
        // We reset the user to add
        itemToAdd.value = null;
      })
    }
    return {
      listLoading,
      listProducts,
      listFields,
      confirmRemoveItem,
      removeItem,
      removeItemModal,
      itemToRemove,
      onAddProductFormSubmit,
      listProductsToAdd,
      itemToAdd

    }
  }
})
</script>