import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface MethodsOfPaymentAdminInput {
}

export function useMethodsOfPaymentAdmin(props:MethodsOfPaymentAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const testCardForm:Ref<any> = ref({});
  const liveCardForm:Ref<any> = ref({});
  const stagingSelectedType:Ref<'test'|'live'|undefined> = ref(undefined);
  const productionCardConfig:Ref<any> = ref(null);  

  const isCardCreateAllowed:Ref<boolean> = ref(false);
  const isCardPublishAllowed:Ref<boolean> = ref(false);

  const updateMethodsOfPayment = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment');
      // We check the additional fields
      if(response.cards) {
        if(response.cards.staging) {
          if(response.cards.staging.test) {
            testCardForm.value = response.cards.staging.test.configuration;
          }
          else {
            testCardForm.value = undefined;
          }
          if(response.cards.staging.live) {
            liveCardForm.value = response.cards.staging.live.configuration;
          }
          else {
            liveCardForm.value = undefined;
          }
          stagingSelectedType.value = response.cards.staging.selectedType;
        }
        if(response.cards.production) {
          productionCardConfig.value = {
            ...response.cards.production.configuration,
            isTest : response.cards.production.isTest
          }
        }
      }
      if(response.options) {
        isCardCreateAllowed.value = response.options.isCreateAllowed;
        isCardPublishAllowed.value = response.options.isPublishAllowed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateMethodsOfPayment();

  const getStripeConnectURL = async (type:'test'|'live') => {

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/'+type+'-card/connect');
      // We check the additional fields
      return response.connectURL;
    }
    catch(error:any) {
      console.log(error);
    }
    return undefined;
  }

  const createCard = async (type:'test'|'live') => {

    var input = {
      ...((type == 'test') ? testCardForm.value : liveCardForm.value)
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/'+type+'-card/create', input);
      // We check the additional fields
      if(response.card) {
        if(type == 'test') { testCardForm.value = response.card.configuration; }
        else { liveCardForm.value = response.card.configuration; }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const selectCard = async (type:'test'|'live') => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/'+type+'-card/select');
      // We check the additional fields
      if(response.selectedType) {
        stagingSelectedType.value = response.selectedType;
      }
    }  
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return undefined;
  }

  const publishCard = async (type:'test'|'live') => {

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/'+type+'-card/publish');
      // We check the additional fields
      if(response.card) {
        productionCardConfig.value = {
          ...response.card.configuration,
          isTest: response.card.isTest
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the methodsOfPayment when the selected shop is updated
      updateMethodsOfPayment();
    },
    { deep: false }
  )

  return {
    isListLoading,
    testCardForm,
    liveCardForm,
    stagingSelectedType,
    getStripeConnectURL,
    createCard,
    selectCard,
    publishCard,
    productionCardConfig,
    isCardCreateAllowed,
    isCardPublishAllowed
  }
  
}