<template>
    
  <form role="form" @submit="onPickupFormSubmit" name="update-pickup">
    <h4>
      <TextField
        ref="name"
        v-bind:value.sync="form.name" 
        id="updateShippingName" 
        :label="$t('shop.settings.methodsOfDelivery.common.name')" 
        :placeholder="$t('shop.settings.methodsOfDelivery.common.name_placeholder')" 
        :labelAsColumn="true"
        :required="true"
        :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
      />
    </h4>

    <LocalizedText 
      ref="title"
      v-bind:value.sync="form.title" 
      id="updatePickupTitle" 
      :label="$t('shop.settings.methodsOfDelivery.common.title')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.common.title_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-title':true}"
    />
    <LocalizedText 
      ref="note"
      v-bind:value.sync="form.note" 
      id="updatePickupNote" 
      :label="$t('shop.settings.methodsOfDelivery.common.note')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.common.note_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
    />
    <div class="form-group row">
      <label for="updateDelayInHours" class="col-lg-3 col-form-label">{{$t('shop.settings.methodsOfDelivery.pickup.delayInHours')}}</label>
      <div class="col-lg-9">
        <textarea id="updateDelayInHours" :placeholder="$t('shop.settings.methodsOfDelivery.pickup.delayInHours_placeholder')" class="form-control" v-model="form.delayInHours" />
      </div>
    </div>
    <div class="address">
      <h4>{{ $t('shop.settings.methodsOfDelivery.pickup.address') }}</h4>
      <Address 
        v-bind:value.sync="form.address"
        :labelAsColumn="true"
        :inputClass="{'form-control' : true}"
        :options="addressOptions"
        required
      />
    </div>
    <button class="btn btn-primary ladda-button update-pickup" data-style="zoom-in" type="submit">{{$t('shop.settings.methodsOfDelivery.pickup.button')}}</button>
    <button class="btn btn-primary cancel-update" type="button" @click="cancel()">{{$t('shop.settings.methodsOfDelivery.pickup.cancel-button')}}</button>
  </form>
      
</template>

<style>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';

import Address from '@fwk-client/components/panels/input/Address.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';

import * as Ladda from 'ladda';

export default defineComponent({
  props: {
      pickup : {
        type: Object,
        required: false
      }
  },
  components: {
    Address,
    LocalizedText,
    TextField
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { updateMethodOfDelivery } = useMethodsOfDeliveryAdmin(props, context);

    const { pickup } = toRefs(props);

    const availableLanguageCodes = ['fr','en'];

    const addressOptions = {
      showName: true,
      nbAddressLines: 2
    }

    const emptyPickup = {
      title : "",
      note : "",
      address : undefined,
      delayInHours : "",
      type : 'PICKUP'
    }

    var form:any = reactive(pickup.value ? { ...pickup.value } : { ...emptyPickup });

    var laddaUpdate:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var updateButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-pickup] button.update-pickup' );
      laddaUpdate = Ladda.create(updateButton!);
    })

    const onPickupFormSubmit = (evt:Event) => {
      evt.preventDefault();

      laddaUpdate!.start();
      updateMethodOfDelivery(form).then((response:any) => {
        if(response.updated) {  
          var delivery = response.delivery;
          // We emit event as user is updated
          context.emit('updated', delivery);
        }

        laddaUpdate!.stop();
      })
    }

    const cancel = () => {
      context.emit('cancel');
    }

    watch(
      pickup,
      (val:any, oldVal:any) => {
        if(val) {
          form = reactive(val);
        }
        else {
          form = reactive({ ...emptyPickup })
        }
      },
      { deep: true }
    )

    return {
      availableLanguageCodes,
      addressOptions,
      form,
      onPickupFormSubmit,
      cancel
    }
  }
})
</script>