<template>
  <ul v-if="pricePerItems">
    <li v-for="(pricePerItem, indexPricePerItem) in pricePerItems" :key="indexPricePerItem + '-price'">
      <span v-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems == undefined && pricePerItem.output.price.amount == 0" class="free">{{ $t('shop.settings.methodsOfDelivery.shipping.'+itemType.toLowerCase()+'.price.free-from', [pricePerItem.facts.minNumberOfItems]) }}</span>
      <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems != undefined && pricePerItem.facts.minNumberOfItems == pricePerItem.facts.maxNumberOfItems" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.settings.methodsOfDelivery.shipping.'+itemType.toLowerCase()+'.price.exact', [pricePerItem.facts.minNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
      <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems != undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.settings.methodsOfDelivery.shipping.'+itemType.toLowerCase()+'.price.between', [pricePerItem.facts.minNumberOfItems, pricePerItem.facts.maxNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
      <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems == undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.settings.methodsOfDelivery.shipping.'+itemType.toLowerCase()+'.price.from', [pricePerItem.facts.minNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
      
      <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount == undefined && pricePerItem.output.price.amount == 0" class="free">{{ $t('shop.settings.methodsOfDelivery.shipping.productsAmount.price.free-from', [formatPriceAmount(pricePerItem.facts.minProductsAmount)]) }}</span>
      <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount != undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.settings.methodsOfDelivery.shipping.productsAmount.price.between', [formatPriceAmount(pricePerItem.facts.minProductsAmount), formatPriceAmount(pricePerItem.facts.maxProductsAmount), formatPriceAmount(pricePerItem.output.price)]) }}</span>
      <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount == undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.settings.methodsOfDelivery.shipping.productsAmount.price.from', [formatPriceAmount(pricePerItem.facts.minProductsAmount), formatPriceAmount(pricePerItem.output.price)]) }}</span>
    </li>
  </ul>
</template>

<style>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'

export default defineComponent({
  props: {
      itemType : {
        type: String,
        required: true
      },
      pricePerItems : {
        type: Array,
        required: true
      },
      minimumNumberOfItems : {
        type: Number,
        required: false
      }
  },
  components: {
  },
  setup(props, context) {

    const { itemType, pricePerItems, minimumNumberOfItems } = toRefs(props);

    return {
      itemType, 
      pricePerItems, 
      minimumNumberOfItems
    }
  }
})
</script>