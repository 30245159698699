<template>
  <div class="productsWithMissingDeliveryTypes" v-if="productsWithMissingTypes && productsWithMissingTypes.length > 0">
    <h3>{{ $t('shop.settings.methodsOfDelivery.products-without-delivery-types.title') }}</h3>

    <b-table 
        outlined striped
        :items="productsWithMissingTypes"
        :fields="listFields"
        ref="listItems">
      <template v-slot:cell(select)="row">
        <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
      </template>
      <template v-slot:cell(title)="row">
        {{row.item.title}}
        <span v-if="row.item.subtitle && row.item.subtitle!=''">
          - {{row.item.subtitle}}
        </span>
      </template> 
      <template v-slot:cell(isActivated)="row">
        <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
      </template> 
    </b-table>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useProductAdmin } from '../../../../composables/useProductAdmin';
import { useShopAdmin } from '../../../../composables/useShopAdmin';
import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';

export default defineComponent({
  props: {
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const router = useRouter();
    const store = useStore();

    const { selectedShop } = useShopAdmin(props, context);
    const { isProductUpdateAllowed }  = useProductAdmin(props, context);
    const { getProductsWithMissingTypes }  = useMethodsOfDeliveryAdmin(props, context);

    const isProductsWithMissingTypesLoading:Ref<boolean> = ref(false);
    const listLoading:Ref<boolean> = computed(() => {
      return isProductsWithMissingTypesLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);

    const productsWithMissingTypes:Ref<any[]> = ref([]);


    const methodOfDeliveryTypeFormatter = () => {
      return (value:any, key:any, item:any) => {
        var result = "";
        for(var key of value) {
          if(result != "") { result += ", "; }
          result += app.$t('shop.orders.methodsOfDelivery.'+key) as string;
        }
        return result;
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "missingTypes",
        label: "",
        formatter: methodOfDeliveryTypeFormatter()
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.products.products.headers.title') as string;
      listFields[2].label = app.$t('shop.products.products.headers.isActivated') as string;
      listFields[3].label = app.$t('shop.products.products.headers.missingDeliveryTypes') as string;
    })

    const updateProductsWithMissingTypes = () => {
      isProductsWithMissingTypesLoading.value = true;
      getProductsWithMissingTypes(selectedShop.value.shop._id).then((products) => {
        isProductsWithMissingTypesLoading.value = false;
        productsWithMissingTypes.value = products;
      })
    }
    updateProductsWithMissingTypes();

    return {
      listLoading,
      productsWithMissingTypes,
      listFields,
      listItems,
      listItemsSelected,
      isProductUpdateAllowed
    }
  }
})
</script>