<template>
  <div class="ibox methodsOfDelivery">
    <div class="ibox-title">
      <h2>{{ $t('shop.settings.methodsOfDelivery.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <ProductsWithoutDeliveryTypes></ProductsWithoutDeliveryTypes>

      <div class="pickups" v-if="pickups && pickups.length > 0">
        <h3>{{ $t('shop.settings.methodsOfDelivery.pickup.title') }}</h3>
        <div v-for="(pickup, index) in pickups" :key="index + '-pickup'">
          <MethodOfDelivery :delivery="pickup"></MethodOfDelivery>
          <div v-if="index > 0" class="hr-line-dashed"></div>
        </div>
      </div>

      <div v-if="pickups && pickups.length > 0 && shippings && shippings.length > 0" class="hr-line-dashed"></div>

      <div class="shippings" v-if="shippings && shippings.length > 0">
        <h3>{{ $t('shop.settings.methodsOfDelivery.shipping.title') }}</h3>
        <div v-for="(shipping, index) in shippings" :key="index + '-shipping'">
          <MethodOfDelivery :delivery="shipping"></MethodOfDelivery>
          <div v-if="index > 0" class="hr-line-dashed"></div>
        </div>
      </div>

      <div v-if="(pickups && pickups.length > 0) || (shippings && shippings.length > 0)" class="hr-line-dashed"></div>

      <b-modal size="xl" ref="createDeliveryModal" :title="$t('shop.settings.methodsOfDelivery.create.title')" hide-footer lazy>
        <CreateDelivery v-on:delivery-created="onDeliveryCreated" @cancel-creation="cancelCreation()" />
      </b-modal>

      <button class="btn btn-primary create-delivery" type="button" @click="showCreateDeliveryModal()">{{$t('shop.settings.methodsOfDelivery.create-button')}}</button>
    
    </div>
  </div>
</template>

<style>

</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useMethodsOfDeliveryAdmin } from '../../../composables/useMethodsOfDeliveryAdmin';

import MethodOfDelivery from './methodsOfDelivery/MethodOfDelivery.vue'
import CreateDelivery from './methodsOfDelivery/CreateForm.vue'
import ProductsWithoutDeliveryTypes from './methodsOfDelivery/ProductsWithoutDeliveryTypes.vue';

export default defineComponent({
  props: {
  },
  components: {
    MethodOfDelivery,
    CreateDelivery,
    ProductsWithoutDeliveryTypes
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { updateMethodsOfDelivery, pickups, shippings } = useMethodsOfDeliveryAdmin(props, context);

    const listLoading:boolean = false;

    const createDeliveryModal:Ref<HTMLElement|null> = ref(null);

    const showCreateDeliveryModal = () => {
      // @ts-ignore
      createDeliveryModal.value.show()
    }

    const cancelCreation = () => {
      // @ts-ignore
      createDeliveryModal.value.hide()
    }

    const onDeliveryCreated = () => {
      updateMethodsOfDelivery();
    }

    return {
      listLoading,
      createDeliveryModal,
      showCreateDeliveryModal,
      cancelCreation,
      onDeliveryCreated,
      pickups,
      shippings
    }
  }
})
</script>