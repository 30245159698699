<template>

    <div>
        <div class="form-group row">
            <label for="type" class="col-lg-3 col-form-label">{{$t('shop.settings.methodsOfDelivery.type')}}</label>
            <div class="col-lg-9">
                <v-select id="type" :placeholder="$t('shop.settings.methodsOfDelivery.type_placeholder')" v-model="form.type" :options="listTypes" :getOptionLabel="option => $t('shop.orders.methodsOfDelivery.'+option)"></v-select>
            </div>
        </div>

        <PickupForm v-if="form.type == 'PICKUP'" @cancel="onCancel()" @updated="onUpdated"></PickupForm>
        <ShippingForm v-else-if="form.type == 'SHIPPING'" @cancel="onCancel()" @updated="onUpdated"></ShippingForm>

    </div>
  
</template>

<style>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import PickupForm from './PickupForm.vue'
import ShippingForm from './ShippingForm.vue'

export default defineComponent({
  props: {
  },
  components: {
    PickupForm,
    ShippingForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const listTypes = ['PICKUP', 'SHIPPING'];

    const form:any = reactive({
      type : ''
    });

    const onCancel = () => {
      context.emit('cancel');
    }

    const onUpdated = (delivery:any) => {
      context.emit('created', delivery)
    }

    return {
      listTypes,
      form,
      onCancel,
      onUpdated
    }
  }
})
</script>