<template>
  <form role="form" @submit="onShippingFormSubmit" name="update-shipping">
    <h4>
      <TextField
        ref="name"
        v-bind:value.sync="form.name" 
        id="updateShippingName" 
        :label="$t('shop.settings.methodsOfDelivery.common.name')" 
        :placeholder="$t('shop.settings.methodsOfDelivery.common.name_placeholder')" 
        :labelAsColumn="true"
        :required="true"
        :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
      />
    </h4>
    <Select
      :label="$t('shop.settings.methodsOfDelivery.shipping.itemType')"
      :selectOptions="{
        options: itemTypes,
        getOptionLabel: getItemTypeLabel
      }"
      v-bind:value.sync="form.itemType" 
      :labelAsColumn="true"
      :required="true"
      :rowClass="{'form-group':true, 'row':true, 'update-itemType':true}"
    />
    <LocalizedText 
      ref="title"
      v-bind:value.sync="form.title" 
      id="updateShippingTitle" 
      :label="$t('shop.settings.methodsOfDelivery.common.title')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.common.title_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-title':true}"
    />
    <LocalizedText 
      ref="note"
      v-bind:value.sync="form.note" 
      id="updateShippingNote" 
      :label="$t('shop.settings.methodsOfDelivery.common.note')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.common.note_placeholder')" 
      :labelAsColumn="true"
      :required="false"
      :languageCodes="availableLanguageCodes"
      :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
    />
    <TextField
      ref="updateMinimumNumberOfItems"
      fieldType="number"
      v-bind:value.sync="form.minimumNumberOfItems" 
      id="updateMinimumNumberOfItems" 
      :label="$t('shop.settings.methodsOfDelivery.shipping.'+form.itemType.toLowerCase()+'.minimumNumberOfItems')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.shipping.'+form.itemType.toLowerCase()+'.minimumNumberOfItems')" 
      :labelAsColumn="true"
      :required="true"
      :rowClass="{'form-group':true, 'row':true}"
    />
    <TextField
      ref="updateNumberOfItemsPerBox"
      fieldType="number"
      v-bind:value.sync="form.numberOfItemsPerBox" 
      id="updateNumberOfItemsPerBox" 
      :label="$t('shop.settings.methodsOfDelivery.shipping.'+form.itemType.toLowerCase()+'.numberOfItemsPerBox')" 
      :placeholder="$t('shop.settings.methodsOfDelivery.shipping.'+form.itemType.toLowerCase()+'.numberOfItemsPerBox')" 
      :labelAsColumn="true"
      :required="true"
      :rowClass="{'form-group':true, 'row':true}"
    />
    <div class="form-group row">
      <div class="col">
        <ShippingPrices v-bind:shipping.sync="form"></ShippingPrices>
      </div>
    </div>
    
    <button class="btn btn-primary ladda-button update-shipping" data-style="zoom-in" type="submit">{{$t('shop.settings.methodsOfDelivery.shipping.button')}}</button>
    <button class="btn btn-primary cancel-update" type="button" @click="cancel()">{{$t('shop.settings.methodsOfDelivery.shipping.cancel-button')}}</button>
  </form>
</template>

<style>
  
</style>



<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useMethodsOfDeliveryAdmin } from '../../../../composables/useMethodsOfDeliveryAdmin';

import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import ShippingPrices from './ShippingPrices.vue';

import * as Ladda from 'ladda';

export default defineComponent({
  props: {
      shipping : {
        type: Object,
        required: false
      }
  },
  components: {
    LocalizedText,
    ShippingPrices,
    TextField,
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { updateMethodOfDelivery } = useMethodsOfDeliveryAdmin(props, context);

    const { shipping } = toRefs(props);

    const availableLanguageCodes = ['fr','en'];

    const itemTypes:string[] = [
      "ITEMS",
      "BOTTLES"
    ];

    const emptyShipping = {
      title : "",
      note : "",
      minimumNumberOfItems : null,
      numberOfItemsPerBox : null,
      shippingPrices : [],
      type : 'SHIPPING',
      itemType : 'ITEMS'
    }

    var form:any = reactive(shipping.value ? { ...shipping.value } : { ...emptyShipping });

    var laddaUpdate:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var updateButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-shipping] button.update-shipping' );
      laddaUpdate = Ladda.create(updateButton!);
    })

    const getItemTypeLabel = (itemType:string) => {
      return app.$t('shop.settings.methodsOfDelivery.shipping.'+itemType.toLowerCase()+'.name');
    }

    const onShippingFormSubmit = (evt:Event) => {
      evt.preventDefault();

      laddaUpdate!.start();
      updateMethodOfDelivery(form).then((response:any) => {
        if(response.updated) {  
          var delivery = response.delivery;
          // We emit event as user is updated
          context.emit('updated', delivery);
        }

        laddaUpdate!.stop();
      })
    }

    const cancel = () => {
      context.emit('cancel');
    }

    watch(
      shipping,
      (val:any, oldVal:any) => {
        if(val) {
          form = reactive(val);
        }
        else {
          form = reactive({ ...emptyShipping })
        }
      },
      { deep: true }
    )

    return {
      availableLanguageCodes,
      itemTypes,
      getItemTypeLabel,
      form,
      onShippingFormSubmit,
      cancel
    }
  }
})
</script>